<template>
  <v-dialog :value="value" persistent width="500px">
    <v-card color='#fafafa' width='500px'>
      <v-toolbar flat dark class="main">
        <v-toolbar-title>
        Statement Document Upload
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 px-4" fluid>
        <v-col>
          <v-menu
            v-model="run_dt_picker"
            transition="scale-transition"
            offset-y
            nudge-top="25"
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="run_date"
                label="Run Date"
                ref="run_date"
                type="date"
                prepend-inner-icon="mdi-calendar"
                dense
                background-color="#fff"
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[isValidRunDate]">
              </v-text-field>
            </template>
            <v-date-picker 
              v-model="run_date"
              :max="today"
              no-title>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-card-text class="mt-n8">
          <span><strong>File Select:</strong></span>
          <v-row class="mt-1">
            <template v-for="file in currentFiles">
              <v-checkbox
                :key="file.key"
                dense 
                class="ml-2"
                v-model="file.value"
                :label="file.label">
              </v-checkbox>
            </template>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-n3">
          <span><strong>Warning:</strong></span>
          <v-checkbox
            dense
            v-model="agreed"
            :label="warningText">
          </v-checkbox>
        </v-card-text>
      </v-container>
      <v-footer color="#fafafa">
        <v-spacer/>
        <v-btn
          color="gray"
          class="mb-3 mr-5"
          dense
          medium
          @click="$emit('closeProcessModal')">
          Cancel
        </v-btn>
        <v-btn
          color="success"
          class="mb-3 mr-5"
          dense
          medium
          :disabled="startButtonDisabled"
          :loading="loading"
          @click="triggerStatementDocUpload()">
          Start
        </v-btn>
      </v-footer>
    </v-card> 
  </v-dialog>
</template>
<script>
import { displayAlert } from '@/mixins/displayAlert'
import { utils } from '@/mixins/utils'
export default {
  name: 'StatementDocumentUploadModal',
  props: ['value'],
  mixins: [displayAlert, utils],
  data () {
    return {
      run_date: new Date().toISOString().slice(0, 10),
      today: new Date().toISOString().slice(0, 10),
      run_dt_picker: false,
      agreed: false,
      loading: false,
      valid: true,
      warningText: "I understand that running document upload processes before all statements for a given day have finished will result in missing documents.",
      files: [
        { label: 'Vendor Files', key: 'vendor', value: false, show: true },
        { label: 'Retail Files', key: 'retail', value: false, show: true },
        { label: 'Admin Files', key: 'admin', value: false, show: (this.$auth.tenant === 'pricechopper') }
      ]
    }
  },
  computed: {
    currentFiles () {
      return this.files.filter(file => file.show)
    },
    startButtonDisabled () {
      return (!this.agreed || this.loading || !this.valid)
        || this.files.every(file => !file.value)
    },
    successText () {
      const labels = this.files.flatMap(file => file.value ? file.label : [])
      const text = (labels.length === 3) ?
        `${labels[0]}, ${labels[1]}, and ${labels[2]}`
        : labels.join(' and ')
      return `Successfully started ${text} Document Upload`
    }
  },
  methods: {
    async triggerStatementDocUpload() {
      this.loading = true
      try {
        const params = this.files.reduce((obj, file) => {
          obj[file.key] = file.value
          return obj
        }, {})
        params.folder_date = this.run_date
        const res = await this.$Statements.triggerStatementDocUpload(params)
        if (res?.status === 200) {
          this.emitAlert(true, 'success', this.successText)
        }
        this.$emit('closeProcessModal')
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    isValidRunDate (value) {
      if (value) {
        if (!this.isValidYear(value)) {
          this.run_dt_picker = false
          this.valid = false
          return 'Invalid year value'
        }
        this.valid = true
        return true
      }
      this.valid = false
      return 'Run Date is required'
    },
    isValidYear (value) {
      if (value) {
        let [year] = value.split('-')
        year = Number(year)
        const currentYear = new Date().getFullYear()
        if (year == currentYear) {
          return true
        } else {
          return false
        }
      }
      return false
    }
  }
}
</script>
<style>
  .v-text-field__slot input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .v-text-field__slot input[type="date"] {
    cursor: text;
  }

  .v-text-field__slot input[type="date"]::-webkit-input-placeholder {
    visibility: hidden;
  }
</style>
